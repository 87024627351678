<template>
  <section class="app-ecommerce-details">
    <!-- Content -->
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body" v-if="errors_back != null">
        <span>{{ errors_back }}</span>
      </div>
    </b-alert>
    <!-- Brand Information -->
    <b-row class="d-flex justify-content-center" style="margin-top: 90px;">
      <b-col md="6">
        <b-card class="text-center">
          <b-badge class="profile-badge" variant="light-primary">
            <span
              v-for="(translation, index) in sensorData.translation"
              style="font-size: 25px; margin: auto;"
              :key="index"
            >
              {{ translation.name }}
              <span
                v-if="
                  sensorData.translation.length > 1 &&
                  index < sensorData.translation.length - 1
                "
                >-</span
              >
            </span>
          </b-badge>
          <hr class="mb-2" />
          <div class="d-flex justify-content-between align-items-left">
            <h5>
              Status:
              <b-badge variant="primary">{{ sensorData.active }}</b-badge>
            </h5>
            <h5>
              Type:
              <b-badge variant="primary">{{ sensorData.type }}</b-badge>
            </h5>
            <h5>
              OS Type:
              <b-badge variant="primary">{{ sensorData.os_type }}</b-badge>
            </h5>
            <h5>
              Is Score:
              <b-badge variant="primary">{{ sensorData.is_score }}</b-badge>
            </h5>
            <h5>
              Score:
              <b-badge variant="primary">{{ sensorData.score }}</b-badge>
            </h5>
          </div>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <div v-if="delCat" class="">
            <b-button
              block
              v-if="authPermissions.includes('update')"
              :to="{ path: '/sensors/update/' + sensorData.id }"
              variant="primary mr-1 mt-1"
            >
              Update
            </b-button>
            <b-button
              block
              v-if="authPermissions.includes('delete')"
              @click="deleteData(sensorData.id)"
              variant="outline-danger"
              class=""
            >
              Delete
            </b-button>
          </div>
          <div v-else class="d-flex justify-content-center">
            <b-button
              block
              v-if="authPermissions.includes('update')"
              @click="restoreSensor(sensorData.id)"
              variant="outline-danger"
              class="mr-1 mt-1"
            >
              Restore
            </b-button>
          </div>
          <hr />
          <div
            v-if="delCat"
            class="d-flex justify-content-md-center align-items-center text-center"
          >
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Created at
              </h6>
              <h3 class="mb-0">
                {{ sensorData.created_at }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Updated at
              </h6>
              <h3 class="mb-0">
                {{ sensorData.updated_at }}
              </h3>
            </div>
          </div>
          <div
            v-else
            class="d-flex justify-content-md-center align-items-center text-center"
          >
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Created at
              </h6>
              <h3 class="mb-0">
                {{ sensorData.created_at }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Deleted at
              </h6>
              <h3 class="mb-0">
                {{ sensorData.deleted_at }}
              </h3>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-card title="Description">
      <!-- Sensor Translations -->
      <b-card-body>
        <b-tabs align="center" class="text-center">
          <b-tab
            v-for="(translation , index) in sensorData.translation"
            :title="translation.locale == 'en' ? 'English' : 'Arabic'"
            :key="index"
          >
            <b-card-text>
              <div  v-html="translation.description">

              </div>
            </b-card-text>
            <b-button
              v-if="authPermissions.includes('update') && delCat"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="warning"
              class="btn-cart"
              :to="{
                name: 'Sensor-Update-Trans',
                params: { id: sensorData.id, language: translation.locale },
              }"
            >
              <feather-icon icon="RefreshCwIcon" class="mr-50" />
              <span>Update {{ translation.locale }}</span>
            </b-button>
            <b-button
              v-if="
                authPermissions.includes('delete') &&
                translation.locale != 'en' &&
                translation.locale != 'ar' &&
                delCat
              "
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="danger"
              class="btn-cart ml-1"
              @click="deleteTranslation(translation.id)"
            >
              <feather-icon icon="DeleteIcon" class="mr-50" />
              <span>Delete {{ translation.locale }}</span>
            </b-button>
          </b-tab>
          <!-- add new translation -->
          <b-button
            v-if="authPermissions.includes('create')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="success"
            block
            class="btn-cart mt-3"
            :to="{ path: '/sensors/translation/add/' + sensorData.id }"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span>Add New Translation</span>
          </b-button>
        </b-tabs>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>

export default {

  data() {
    return {
      id: 0,
      sensorData: {},
      errors_back: [],
      showDismissibleAlert: false,
      delCat: true,
      authPermissions:[]
    }
  },
  mounted() {
    // rquest brand data
  },
  created() {
    this.id = this.$route.params.id
    this.authPermissions = this.$store.state.authPermissions
    this.fetchData()
  },
  methods: {
    fetchData() {
      axios
        .get('sensors/' + this.id)
        .then((result) => {
          this.sensorData = result.data.data
          
          if (this.sensorData.deleted_at != null) {
            this.delCat = false
          } else {
            this.delCat = true
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    // delete function to delete brand
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete sensor.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('sensors/' + id)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    deleteTranslation(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete sensor.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('sensors/translations/' + id + '/destroy')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    restoreSensor(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to restore sensor.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get('sensors/' + id + '/restore', this.restoreSelected)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
</style>
